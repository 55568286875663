






















































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import {
  deleteExamProject,
  getExamProjectList,
  getExamProjectSchoolList,
} from "@/api/examProject";
import { getExamTimeList } from "@/api/examTime";
import { ExamProjectItem, ExamProjectSchool } from "@/classes/exam-project";
import { buildExamAnswerPackages } from "@/api/examResource";
import { ExamTime } from "@/tool/interface-index";
import * as ResApi from "@/api/examFileResource";
import * as ExamPrjApi from "@/api/examProject";
import { getSchoolCascadeList } from "@/api/school";
import * as _ from "lodash";
import dayjs from "dayjs";
import * as CommentApi from "@/api/examComment";
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { resolve } from "dns";

@Component({
  name: "ExamProjectListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };

  private query: {
    keyWords: string;
    examSchoolId: string;
    examProjectId: string;
    examStartTimeWithinDays: number;
  } = {
    examSchoolId: "",
    keyWords: "",
    examProjectId: "",
    examStartTimeWithinDays: 30,
  };
  private schools: any[] = [];
  handleChangeCascadeExamSchool(examSchoolId: string) {}

  private tableData: Array<ExamProjectItem> = [];
  private loading: boolean = false;
  private async getExamProjectData() {
    try {
      this.loading = true;
      const res = await getExamProjectList({
        examTypes: ["mockExamMode"],
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        keyWords: this.query.keyWords,
        examSchoolId: this.query.examSchoolId,
        examProjectId: this.query.examProjectId,
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        mergeExamSchools: true,
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }
  private deleteData(examProjectId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteExamProject(examProjectId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getExamProjectData();
          }
        });
      })
      .catch(() => {});
  }

  get maxWithinDays(): number {
    return 180;
  }

  private optDaysLimited(a: Date): boolean {
    return dayjs(a).isBefore(dayjs().subtract(30, "day"));
  }

  private setExam(examProjectId: string, type: string) {
    this.$router.push({
      path:
        type == "edit"
          ? "editExamProject"
          : type == "add"
          ? "newExamProject"
          : "viewExamProject",
      query: {
        examProjectId: examProjectId,
        type: type,
      },
    });
  }

  private loadingExportAll: boolean = false;

  private handleDropdownCommand(command: string) {
    switch (command) {
      case "exportAllExamTime": {
        this.doExportAllExamTimeClick();
        break;
      }
      case "downloadAllScore": {
        this.doDownloadAllScoreClick();
        break;
      }
    }
  }

  private async doDownloadAllScoreClick() {
    try {
      this.loadingExportAll = true;
      /*
      for (let i = 0; i < this.tableData.length; i++) {
        let item: ExamProjectItem = this.tableData[i];
        await this.doDownloadExamStatisticsReportClick(item);
      }
        */
      await this.doDownloadExamStatisticsReportClick(this.tableData);
    } finally {
      this.loadingExportAll = false;
    }
  }

  private async doExportAllExamTimeClick() {
    try {
      this.loadingExportAll = true;
      if (
        !this.query.examSchoolId &&
        !this.query.examProjectId &&
        !this.query.keyWords
      ) {
        this.$message({
          message: `查询条件不能为空。`,
          type: "warning",
          offset: 60,
        });
        return;
      }
      const tHeader = [
        "学校",
        "项目",
        "场次",
        "试卷",
        "日期",
        "实考人数",
        "平均分",
      ];
      const filterVal = [
        "examSchoolName",
        "examProjectName",
        "examTimeName",
        "examPaperName",
        "examStateTime",
        "examStudentCount",
        "avgScore",
      ];

      const tRow: any[] = [];
      const filename: string = "练习场次统计";
      //
      let curPage = 0;
      let totalPage = 0;
      do {
        curPage++;
        const { data } = await CommentApi.getExamTimesReport({
          pageSize: this.pageNum.pageSize,
          curPage: curPage,
          keyWords: this.query.keyWords,
          examSchoolId: this.query.examSchoolId,
          examProjectId: this.query.examProjectId,
          examType: "mockExamMode", //this.examType,
          examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        });
        curPage = data.curPage;
        totalPage = data.totalPage;
        tRow.push(...formatJson(filterVal, data.items));
        //
      } while (curPage < totalPage);
      //
      exportJson2Excel(tHeader, tRow, filename);
    } finally {
      this.loadingExportAll = false;
    }
  }

  private loadingScore: boolean = false;
  async scoreExamTime(params: {
    examProjectId: string;
    examSchoolId: string;
    examTimeId: string;
    scoreExamAnswerPackages: boolean;
    scoreLocalExamAnswerPackageFiles: boolean;
    recipients: string;
  }) {
    try {
      this.loadingScore = true;
      //
      await ExamPrjApi.postScoreExamProject(params, true);
    } finally {
      this.loadingScore = false;
    } //try 0
  }

  handleExportExamProjectFileClick(project: ExamProjectItem) {
    try {
      const baseUrl: string = process.env.VUE_APP_BASE_API || "";
      ResApi.download(
        `${baseUrl}${
          baseUrl.endsWith("/") ? "" : "/"
        }v1/download/examProjectFiles?examProjectId=${project.examProjectId}`,
        {},
        `${project.examProjectName}.xkwz`
      );
    } catch (error) {
      //
    }
  }

  private wait(timeout: number): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  }

  private loadingDownloadExamStatisticsReport: boolean = false;
  private async doDownloadExamStatisticsReportClick(
    projects: ExamProjectItem[]
  ) {
    if (this.loadingDownloadExamStatisticsReport) {
      this.$message({
        message: "仍有下载任务进行中，请稍候再试。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    try {
      this.loadingDownloadExamStatisticsReport = true;

      for (let i = 0; i < projects.length; i++) {
        let project = projects[i];
        const examProjectId = project.examProjectId;
        const { data } = await getExamProjectSchoolList({
          examProjectId: project.examProjectId,
          curPage: 1,
          pageSize: 9999,
        });

        for (let i = 0; i < data.items.length; i++) {
          const examSchoolId = data.items[i].examSchoolId || "";
          const examTimeId = "";
          const fileName = `${data.items[i].examProjectName}_${data.items[i].examSchoolName}_考生成绩明细[(不含缺考)].xls`;
          this.$message({
            message: `开始下载(${i + 1}/${data.items.length}):${fileName} ...`,
            type: "success",
            duration: 5 * 1000, // 100000
            offset: 60,
          });
          //const baseUrl = process.env.VUE_APP_BASE_API;
          //const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${row.examProjectId}&examSchoolId=${row.examSchoolId}&examTimeId=${row.examTimeId}&fileName=${fileName}`;
          const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${examProjectId}&examSchoolId=${examSchoolId}&examTimeId=${examTimeId}&fileName=${fileName}`;

          await ResApi.download(
            `${examStudentsReportShortFileUrl}`,
            {},
            fileName
          );
          //归避浏览器限制下载频率
          this.wait(500);
        }
      } //for projects
    } catch (error) {
      //
    } finally {
      this.loadingDownloadExamStatisticsReport = false;
    }
  }

  private loadingBuildExamAnswerPackages: boolean = false;
  async doBuildExamAnswerPackagesClick(project: ExamProjectItem) {
    if (this.loadingBuildExamAnswerPackages) {
      this.$message({
        message: "仍有“重做答案包”任务处理中，请稍候再试。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    await this.$confirm(
      `重做答案包：${project.examProjectName}, 请确保作答文件已上传至平台, 是否继续?`,
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    );
    try {
      this.loadingBuildExamAnswerPackages = true;
      //
      const { data } = await buildExamAnswerPackages({
        examProjectId: project.examProjectId,
      });
      this.$message({
        message: `已重做${data.items.length}个答案包`,
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
      this.loadingBuildExamAnswerPackages = false;
    }
  }

  private loadingScoreExamProject: boolean = false;
  async doReScoringExamProjectClick(project: ExamProjectItem, tag: string) {
    if (this.loadingScoreExamProject) {
      this.$message({
        message: "仍有测评任务处理中，请稍候再试。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    await this.$confirm(`测评：${project.examProjectName}, 是否继续?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    try {
      this.loadingScoreExamProject = true;
      //2021/09/08测评不需要邮件与发邮件功能分离
      //await this.promptRecipients();
      //
      switch (tag) {
        case "examAnswerPackageFiles": {
          //评答案包文件
          try {
            this.$message({
              message: `开始测评异常考生数据包 ...`,
              type: "success",
              duration: 5 * 1000, // 100000
              offset: 60,
            });
            await this.scoreExamTime({
              examProjectId: project.examProjectId,
              examSchoolId: "",
              examTimeId: "",
              scoreExamAnswerPackages: false,
              scoreLocalExamAnswerPackageFiles: true,
              recipients: "" /*this.recipients*/,
            });
          } catch (error) {}
          break;
        }
        default: {
          //评答案包记录
          const { data } = await getExamTimeList({
            examProjectId: project.examProjectId,
            curPage: 1,
            pageSize: 99999,
          });
          for (let i = 0; i < data.items.length; i++) {
            try {
              const examTime = data.items[i];
              this.$message({
                message: `开始测评(${i + 1}/${data.items.length}):${
                  examTime.examTimeName
                } ...`,
                type: "success",
                duration: 5 * 1000, // 100000
                offset: 60,
              });
              await this.scoreExamTime({
                examProjectId: examTime.examProjectId,
                examSchoolId: examTime.examSchoolId,
                examTimeId: examTime.examTimeId,
                scoreExamAnswerPackages: true,
                scoreLocalExamAnswerPackageFiles: false,
                recipients: "" /*this.recipients*/,
              });
            } catch (error) {}
          }
        }
      }

      this.$message({
        message: "测评任务已全部提交，请去查看测试结果。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
      this.loadingScoreExamProject = false;
    }
  }

  doRouteToExamTimeListClick(row: ExamProjectItem) {
    this.$router.push({
      path: "examAutoScoreList",
      query: {
        examProjectId: row.examProjectId,
        examSchoolId: this.query.examSchoolId,
        examType: "mockExamMode",
      },
    });
  }

  private upDataPage() {
    this.getExamProjectData();
  }

  private queryExamProjectDataClick() {
    this.pageNum.curPage = 1;
    this.getExamProjectData();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  private __init() {
    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
  }

  mounted() {
    if (this.$route.query.examSchoolId) {
      this.query.examSchoolId = this.$route.query.examSchoolId as string;
    }

    this.__init();
    //this.getExamProjectData();
  }
  activated() {
    if (this.$route.query.examSchoolId) {
      this.query.examSchoolId = this.$route.query.examSchoolId as string;
    }
    this.getExamProjectData();
  }
}
